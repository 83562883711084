<template>
<div class=" block home">
<v-container>
    <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600&display=swap" rel="stylesheet">  
    <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600&display=swap" rel="stylesheet">
        <div class='homeTitle'>
          <h2 class='text-center'>{{ $t('partners.title')}}</h2>           
        </div>

        <v-row
        justify='center'
        align='center'
        >
        <v-col
          :cols = '12'
          :md = '6'
          align = 'center'>
            <v-hover>
              <template
              v-slot:default="{ hover }">
                <v-img
                :aspect-ratio="16/9"
                src="../assets/photos/chateau_vecquee.jpg"
                >
                  <template v-slot:placeholder>
                      <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                      >
                      <v-progress-circular
                          indeterminate
                          color="amber"
                      ></v-progress-circular>
                      </v-row>
                  </template>   

                  <v-fade-transition>
                    <v-overlay
                      v-if="hover"
                      absolute
                      color="rgb(236, 219, 68)"
                    >
                      <v-btn
                      color='white'
                      ><a href="https://vecquee.be/" target="_blank">{{ $t('partners.button')}}</a>
                      </v-btn>
                    </v-overlay>
                  </v-fade-transition>                                 
                </v-img>
              </template>
            </v-hover>
          </v-col>

          <v-col
          :cols = '12'
          :md = '6'
          align = 'center'>
            <div>
              <h4>Château  de la Vecquée</h4>
            </div>
          </v-col>

          <v-col
          :cols = '12'
          :md = '6'
          align = 'center'>
            <v-hover>
              <template
              v-slot:default="{ hover }">
                <v-img
                :aspect-ratio="16/9"
                src="../assets/photos/domainedelonzee.jpg"
                >
                  <template v-slot:placeholder>
                      <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                      >
                      <v-progress-circular
                          indeterminate
                          color="amber"
                      ></v-progress-circular>
                      </v-row>
                  </template>   

                  <v-fade-transition>
                    <v-overlay
                      v-if="hover"
                      absolute
                      color="rgb(236, 219, 68)"
                    >
                      <v-btn
                      color='white'
                      ><a href="http://domaine-de-lonzee.be/" target="_blank">{{ $t('partners.button')}}</a>
                      </v-btn>
                    </v-overlay>
                  </v-fade-transition>                                 
                </v-img>
              </template>
            </v-hover>
          </v-col>
      
          <v-col
          :cols = '12'
          :md = '6'
          align = 'center'>
            <div>
              <h4>Domaine de Lonzée</h4>
            </div>
          </v-col>
          
          <v-col
          :cols = '12'
          :md = '6'
          align = 'center'>
            <v-hover>
              <template
              v-slot:default="{ hover }">
                <v-img
                :aspect-ratio="16/9"
                src="../assets/photos/no_compressed/business_village.jpg"
                >
                  <template v-slot:placeholder>
                      <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                      >
                      <v-progress-circular
                          indeterminate
                          color="amber"
                      ></v-progress-circular>
                      </v-row>
                  </template>   

                  <v-fade-transition>
                    <v-overlay
                      v-if="hover"
                      absolute
                      color="rgb(236, 219, 68)"
                    >
                      <v-btn
                      color='white'
                      ><a href="https://www.businessvillage.be/" target="_blank">{{ $t('partners.button')}}</a>
                      </v-btn>
                    </v-overlay>
                  </v-fade-transition>                                 
                </v-img>
              </template>
            </v-hover>
          </v-col>

          <v-col :cols = '12'
          :md = '6'
          align = 'center'
          >
            <div>      
              <h4>Business Village Ecolys</h4>
            </div>
          </v-col>
        </v-row>
        
        <v-row
        justify='center'
        align='center'
        class='text-img-home flex-md-row'
        >
        <v-col
          :cols = '12'
          :md = '6'
          >
            <v-hover>
              <template
              v-slot:default="{ hover }">
                <v-img            
                  :aspect-ratio="16/9"
                  src="../assets/photos/fermedoudoumont.jpg"
                >
                  <template v-slot:placeholder>
                      <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                      >
                        <v-progress-circular
                            indeterminate
                            color="amber"
                        ></v-progress-circular>
                      </v-row>
                  </template>   

                  <v-fade-transition>
                    <v-overlay
                      v-if="hover"
                      absolute
                      color="rgb(236, 219, 68)"
                    >
                      <v-btn
                      color='white'
                      ><a href="https://www.fermedoudoumont.be/" target="_blank">{{ $t('partners.button')}}</a>
                      </v-btn>
                    </v-overlay>
                  </v-fade-transition>                              
                  </v-img>
              </template>
            </v-hover>
          </v-col>

          <v-col
          :cols = '12'
          :md = '6'
          align = 'center'
          >
            <div> 
              <h4>La Ferme du Château d'Oudoumont</h4>
            </div>          
          </v-col>
        </v-row>
</v-container>
</div>  
</template>

<script>

export default {
  name: 'Formules',
  components: {
    // HelloWorld
  },
  data: () => ({
    alignments: [
        'start',
        'center',
        'end',
      ],
    overlay: false,
  })
}
</script>

<style scoped>
  .home {
      font-family: 'Raleway', sans-serif;
  }

  .block {
  padding: 60px 0;
  border-bottom: 1px solid darken(white, 10%);
}

  .homeTitle {
    font-size: 30px;
    padding: 0 0 10px;
    margin: 0 0 40px;
    position: relative;
  }
</style>
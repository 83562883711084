var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:" block home"},[_c('v-container',[_c('link',{attrs:{"href":"https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500&display=swap","rel":"stylesheet"}}),_c('link',{attrs:{"href":"https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600&display=swap","rel":"stylesheet"}}),_c('link',{attrs:{"href":"https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600&display=swap","rel":"stylesheet"}}),_c('div',{staticClass:"homeTitle"},[_c('h2',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('partners.title')))])]),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":12,"md":6,"align":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{attrs:{"aspect-ratio":16/9,"src":require("../assets/photos/chateau_vecquee.jpg")},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"amber"}})],1)]},proxy:true}],null,true)},[_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"rgb(236, 219, 68)"}},[_c('v-btn',{attrs:{"color":"white"}},[_c('a',{attrs:{"href":"https://vecquee.be/","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('partners.button')))])])],1):_vm._e()],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":12,"md":6,"align":"center"}},[_c('div',[_c('h4',[_vm._v("Château de la Vecquée")])])]),_c('v-col',{attrs:{"cols":12,"md":6,"align":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{attrs:{"aspect-ratio":16/9,"src":require("../assets/photos/domainedelonzee.jpg")},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"amber"}})],1)]},proxy:true}],null,true)},[_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"rgb(236, 219, 68)"}},[_c('v-btn',{attrs:{"color":"white"}},[_c('a',{attrs:{"href":"http://domaine-de-lonzee.be/","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('partners.button')))])])],1):_vm._e()],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":12,"md":6,"align":"center"}},[_c('div',[_c('h4',[_vm._v("Domaine de Lonzée")])])]),_c('v-col',{attrs:{"cols":12,"md":6,"align":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{attrs:{"aspect-ratio":16/9,"src":require("../assets/photos/no_compressed/business_village.jpg")},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"amber"}})],1)]},proxy:true}],null,true)},[_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"rgb(236, 219, 68)"}},[_c('v-btn',{attrs:{"color":"white"}},[_c('a',{attrs:{"href":"https://www.businessvillage.be/","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('partners.button')))])])],1):_vm._e()],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":12,"md":6,"align":"center"}},[_c('div',[_c('h4',[_vm._v("Business Village Ecolys")])])])],1),_c('v-row',{staticClass:"text-img-home flex-md-row",attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":12,"md":6}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{attrs:{"aspect-ratio":16/9,"src":require("../assets/photos/fermedoudoumont.jpg")},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"amber"}})],1)]},proxy:true}],null,true)},[_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"rgb(236, 219, 68)"}},[_c('v-btn',{attrs:{"color":"white"}},[_c('a',{attrs:{"href":"https://www.fermedoudoumont.be/","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('partners.button')))])])],1):_vm._e()],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":12,"md":6,"align":"center"}},[_c('div',[_c('h4',[_vm._v("La Ferme du Château d'Oudoumont")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }